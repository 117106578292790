<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>产品营销</el-breadcrumb-item>
      <el-breadcrumb-item :to="{}" @click.native="returnPage"
        >营销管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ userName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="name">
            <div style="display: inline-block" class="divSpan">活动名称：</div>
            <div style="display: inline-block">
              <el-input
                v-model.trim="queryinfo.name"
                placeholder="请输入"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="userID">
            <div style="display: inline-block" class="divSpan">用户ID：</div>
            <div style="display: inline-block">
              <el-input
                v-model.trim="queryinfo.userID"
                placeholder="请输入"
              ></el-input>
            </div>
          </el-form-item>

          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >

          <el-button type="primary" @click="returnPage" class="buttons"
            >返回</el-button
          >
          <el-button type="primary" @click="exportData" class="buttons1"
            >导出全部销售数据</el-button
          >
        </el-form>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="saleList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="tradeNo"
            label="订单号"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="userId"
            label="用户ID"
            min-width="100px"
          ></el-table-column>

          <el-table-column
            prop="createTime"
            label="下单时间"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="activityName"
            label="活动名称"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="amount"
            label="套餐金额"
            min-width="100px"
          ></el-table-column>
        </el-table>
      </el-row>

      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import { getSellerOrderList } from '@/api/ActionSale.js'
import { exportRecord } from '@/components/myself/DownloadFile'
export default {
  data () {
    return {
      queryinfo: {
        name: '',
        userID: '',
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
      saleList: [],
      ID: '',
      userName: "",

    }
  },
  mounted () {
    this.ID = this.$route.query.id
    this.userName = this.$route.query.name
    this.getList()
  },
  methods: {
    async getList () {
      let quiese = { id: this.ID, activityName: this.queryinfo.name, userId: this.queryinfo.userID }
      const res = await getSellerOrderList(this.queryinfo.pageNum, this.queryinfo.pageSize, quiese)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.saleList = res.data.data.list
      this.total = res.data.data.total
    },
    clean () {
      this.$refs.queryinfoRef.resetFields()
      this.getList()
    },
    dateSerach () {
      this.getList()
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },

    returnPage () {
      this.$router.push('/saleManage')
    },


    exportData () {
      exportRecord({ sellerId: this.ID, activityName: this.queryinfo.name, userId: this.queryinfo.userID }, '销售数据', 5)
    },

  }
}
</script>
<style lang="less" scoped>
.divSpan {
  font-size: 16px;
}

/deep/ .el-form-item__content {
  margin-right: 30px;
}

.buttons {
  float: right;
  margin-right: 100px;
}
.buttons1 {
  float: right;
}
</style>